@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

#aboutn .about-wrapper{
    height: 100vh;
}
#aboutn .about-left{
    background: #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
#aboutn .about-left-content > div{
    background: #bcbcbc;
    padding: 4rem 4rem 2.5rem 5rem;
    text-align: center;
}
#aboutn .about-left-content{
    box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
    -webkit-box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
}
#aboutn .about-img img{
    display: block;
    width: 200px;
}
#aboutn .about-img{
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
}
#aboutn .shadow{
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    position: relative;
    cursor: pointer;
}
#aboutn .shadow::after{
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    height: 7px;
    width: 50px;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    opacity: 0;
    transition: all 0.6s ease;
}
#aboutn .shadow:hover .about-img{
    transform: translateY(-10px);
}
#aboutn .shadow:hover::after{
    opacity: 1;
}
#aboutn .about-left-content h2{
    font-size: 2rem;
    margin: 2.2rem 0 0.6rem 0;
    line-height: 1.2;
    padding-bottom: 1rem;
    border-bottom: 2px solid #edffec;
}
#aboutn .about-left-content h3{
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 5px;
    margin-top: 1.2rem;
}
#aboutn .icons{
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 0.8rem 0;
}
#aboutn .icons li{
    list-style-type: none;
    background: #bcbcbc;
    color: #fff;
    width: 40px;
    height: 40px;
    margin: 0 0.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
#aboutn .icons li:hover{
    background: #eee;
    color: #000;
}
#aboutn .about-right{
    background: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5rem;
    text-align: center;
}
#aboutn .about-right h1{
    font-size: 7rem;
    text-transform: uppercase;
}
#aboutn .about-right h1 span{
    color: #dd2d4a;
}
#aboutn .about-right h2{
    font-weight: 600;
}
#aboutn .about-btns{
    display: flex;
    margin: 2rem 0;
}
#aboutn .btn{
    border: none;
    font-size: 0.9rem;
    text-transform: uppercase;
    border: 2px solid #000;
    border-radius: 20px;
    padding: 0.55rem 0;
    width: 130px;
    font-weight: 600;
    background: transparent;
    margin: 0 0.5rem;
    cursor: pointer;
}
#aboutn .btn.btn-pink{
    background: #dd2d4a;
    color: #fff;
    border-color: #dd2d4a;
    transition: all 0.5s ease-in-out;
}
#aboutn .btn.btn-pink:hover{
    background: transparent;
    border-color: #dd2d4a;
    color: #000;
}
#aboutn .btn.btn-white{
    transition: all 0.5s ease-in-out;
}
#aboutn .btn.btn-white:hover{
    background: #dd2d4a;
    border-color: #dd2d4a;
    color: #fff;
}
#aboutn .about-para p{
    font-weight: 300;
    padding: 0.5rem;
    opacity: 0.8;
}

/* skillls*/

#aboutn #about_a .skills {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 2.5rem;
}

#aboutn #about_a .skills i {
    color: #EF6D6D;
    padding-bottom: .5rem;
}

/* profile */

#aboutn #about_b .profile {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

#aboutn #about_b .profile div { 
    padding: 3rem 0;
}

#aboutn #about_b .profile div .title {
    font-size: 1.5rem;
}

#aboutn #about_b .profile div .number {
    font-size: 1.5rem;
    font-weight: bold;
}

#aboutn .container {
    max-width: 1200px;
    padding: 0 1.4rem;
    margin: auto;
}

#aboutn .bg-light {
    background-color: #f4f4f4;
}
#aboutn .bg-medium {
    background-color: #ccc;
}

#aboutn .py-2 {
    padding: 2rem 0;
}

#aboutn .my-1 { margin: 1rem 0; }
#aboutn .my-2 { margin: 2rem 0; }
#aboutn .my-3 { margin: 3rem 0; }
#aboutn .my-4 { margin: 4.2rem 0; }

#aboutn .text-center {
    text-align: center;
}

#aboutn .section-title {
    font-size: 2rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    text-align: center;
}

#aboutn .border-bottom {
    height: 3px;
    width: 3rem;
    background-color: #EF6D6D;
    margin: 0 auto 1rem auto;
}

#aboutn .lead {
    font-size: 1.4rem;
    word-spacing: 2px;
    margin-bottom: 2rem;
}

#aboutn a {
    text-decoration: none;
    color: #333;
}

#aboutn ul {
    list-style: none;
}

#aboutn img {
    width: 100%;
}

#aboutn {
    line-height: 1.5;
}

#aboutn #about_b {
    margin-top: 10vh !important;
    margin-bottom: 10vh !important;
}

@media screen and (min-width: 992px){
    #aboutn .about-wrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    #aboutn .about-left{
        position: relative;
    }
    #aboutn .about-left-content{
        position: absolute;
        right: -50px;
    }
}

/* tablets */
@media (max-width: 992px) {

    #aboutn .about-wrapper{
        height: 150vh;
    }

    #aboutn #about_a .skills {
        grid-template-columns: repeat(2, 1fr);
    }

    #aboutn #about_b .profile {
        grid-template-columns: repeat(2, 1fr);
    }

    #aboutn .about-left {
        height: 70vh;
    }

    #aboutn .about-right {
        padding: 0;
    }

    #aboutn .about-right h1 {
        font-size: 3rem;
    }

    #aboutn .about-right h2 {
        font-weight: 400;
    }
}

/* Mobile */
@media (max-width: 576px) {

    #aboutn #about_a .skills {
        grid-template-columns: repeat(1, 1fr);
    }

    #aboutn #about_a .skills div {
        border-bottom: 1px solid var(--light-color);
        padding-bottom: 1rem;
    }

    #aboutn #about_a .skills div:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    #aboutn #about_b .profile {
        grid-template-columns: repeat(1, 1fr);
    }

    #aboutn .lead {
        font-size: 1rem;
        word-spacing: 1px;
        margin-bottom: 1.2rem;
    }
}