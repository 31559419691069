@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

#topbar nav{
    width: 100%;
    padding: 1rem 0;
    z-index: 40;
    background-color: rgb(232, 230, 230);
    display: flex;
}

#topbar nav .container{
    max-width: 73rem;
    padding: 0 2rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#topbar .brand-name {
    text-decoration: none;
}

#topbar .logo{
    color: #000;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
}

#topbar .logo span{
    font-weight: 300;
}

#topbar .toggle-menu {
    z-index: 40;
}

#topbar .toggle-menu-icon:hover {
    color: gray;
}

#topbar .side-menu {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: #333;
    color: #fff;
    transition: right 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 35;
}

#topbar .side-menu.open {
    right: 0;
}

#topbar .side-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#topbar .side-menu ul li {
    margin: 30px 0;
}

#topbar .side-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    padding: 15px;
    border-radius: 50px;
}

#topbar .side-menu ul li a:hover {
    background-color: #fff;
    color: black;
}

#topbar .topbar-links {
    display: flex;
    align-items: center;
}

#topbar .topbar-links a {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

#topbar .topbar-links a:hover {
    background-color: #d3d2d2;
}

#topbar .topbar-links .profile-image {
    margin-right: 10px;
}

#topbar .profile-dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#topbar .profile-menu {
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-top: 160px;
    z-index: 50;
    padding: 10px;
}

#topbar .profile-menu a {
    display: block;
    text-decoration: none;
    color: black;
}

#topbar .profile-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

#topbar .profile-menu-item:hover {
    background-color: #ddd;
}

#project-card .my-card {
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 10px 10px 40px rgba(52, 58, 64, 0.1);

    position: relative;
    bottom: 0px;
    transition: all .6s;
}

#project-card .my-card:hover {
    bottom: 10px;
}

.card_padding {
    padding: 10px;
}

#project-card .my-card.card_body {
    padding: 30px;
}

#project-card .nav-link {
    position: relative;
    text-decoration: none;
}

#project-card .nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transition: width 0.3s ease-out; /* Geçiş süresi ve hareket tipi */
}

.card-for-project {
    display:flex;
}

.card-for-project .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-for-project .image-container img {
    height: 300px;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

#message-card .my-card {
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 10px 10px 40px rgba(52, 58, 64, 0.1);

    position: relative;
    bottom: 0px;
    transition: all .6s;
}

#message-card .my-card:hover {
    bottom: 10px;
}

#message-card .card-padding {
    padding: 10px;
}

#message-card .card-for-permission .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

#message-card .card-for-permission .image-container img {
    height: 300px;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

#message-card .card-for-permission .image-container-for-company img {
    width: 12rem;
    height: 9rem;
    object-fit: cover;
}

#message-card .my-card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#message-card .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#message-card .card-footer {
    margin-top: auto;
    flex: 1;
}

#card-feed .row {
    width: 100%;
}

.footer {
    background-color: #f8f9fa;
    text-align: center;
    padding: 1rem 0;
}

.footer .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .privacy {
    color: rgba(33, 37, 41, 0.75);
    padding-left: 5px;
}

#comments .content {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
}

#comments .content img {
    width: 40px;
    height: 40px;
    margin-right: .6rem;
}

#comments .checked {
    color: orange;
}

#comments .content .fa-star {
    font-size: 12px;
}

#comments .btn-light:hover {
    color: #0d6efd;
    border-color: #c1c9d0;
}

#comments .form-control, .form-select {
    background-color: #f9f9f9;
}

#project-detail .row {
    --bs-gutter-x: 0;
}

.btn-fix-css {
    --bs-btn-padding-x: 0 !important;
    --bs-btn-padding-y: 0 !important;
}