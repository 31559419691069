@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Poppins', sans-serif;
}

#home .container{
    max-width: 70rem;
    padding: 0 2rem;
    margin: 0 auto;
}

#home header{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

#home header:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 25;
}

#home .mountain1{
    position: absolute;
    bottom: -100px;
    width: 1500px;
    right: 0;
    z-index: 19;
}

#home .person{
    position: absolute;
    width: 650px;
    bottom: -100px;
    left: -70px;
    z-index: 20;
}

#home .mountain2{
    position: absolute;
    width: 1100px;
    bottom: -100px;
    left: 0;
    z-index: 18;
}

#home .mountain3{
    position: absolute;
    width: 900px;
    bottom: 150px;
    right: 0;
    z-index: 17;
}

#home .sky{
    position: absolute;
    width: 2100px;
    bottom: 250px;
    right: 0;
}

#home nav{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem 0;
    z-index: 30;
    background: linear-gradient(to bottom, rgba( 0, 0, 0, 0.05), transparent);
}

#home nav .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#home .logo{
    color: #fff;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
}

#home .logo span{
    font-weight: 300;
}

#home .hamburger-menu{
    width: 1.55rem;
    height: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#home .bar{
    position: relative;
    width: 1.2rem; 
    height: 3px;
    background-color: #fff;
    border-radius: 2px;
}

#home .bar:before, .bar:after{
    content: '';
    position: absolute;
    width: 1.55rem;
    height: 3px;
    right: 0;
    background-color: #fff;
    border-radius: 2px;
}

#home .bar:before{
    transform: translateY(-8px);
}

#home .bar:after{
    transform: translateY(8px);
}

#home .big-title{
    position: absolute;
    z-index: 30;
    line-height: 4rem;
    top: calc(50% - 2rem);
    width: 100%;
    text-align: center;
    font-size: 5rem;
    font-weight: 600;
    color: #fff;
}

#home section{
    width: 100%;
    background-color: #151515;
    position: relative;
}

#home section .container{
    padding: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

#home section img{
    width: 100%;
}

#home section .container > *{
    margin: 2rem;
}

#home .content{
    color: #fff;
    transform: translateY(-50px);
}

#home .imgContainer{
    transform: translateY(50px);
    display: flex;
    justify-content: center;
}

#home .my-pp {
    height: 40vh;
    width: auto;
}

#home .title{
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
    position: relative;
}

#home .border{
    position: absolute;
    width: 0%;
    height: 3px;
    background-color: #fff;
    bottom: 0;
    left: 0;
}

#home .shadow{
    position: absolute;
    bottom: 100%;
    height: 300px;
    width: 100%;
    left: 0;
    z-index: 20;
    background: linear-gradient(to top, #151515, transparent);
}

#home .opacity{
    opacity: 0;
}

@media (max-width: 850px){
    #home section .container{
        grid-template-columns: 1fr;
    }
}

@media(max-width: 600px){
    #home .big-title{
        font-size: 3rem
    }

    #home .text{
        font-size: .8rem;
    }

    #home .title{
        font-size: 1.2rem;
    }
}